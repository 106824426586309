import React, { useEffect, useState } from "react";

import styled from "styled-components";
import Switch from "react-switch";

import "./Header.scss";

import NakheelLogo from "../../../static/images/nakheel.png";
import DropdownArrow from "../../../static/images/dropdown-arrow.svg";
import MasterCommunities from "../../../static/images/master-communities.svg";
import LanguagePlanet from "../../../static/images/language.svg";

// Header Elements

function HeaderLanguageButton({ link, text }) {
    return (
        <div
            className="header-language-button"
            onClick={() => {
                // document.location.href = link
            }}
        >
            <div className="header-language-inner">
                <div className="header-language-image">
                    <img src={LanguagePlanet} alt="language-planet" />
                </div>
                <div className="header-language-label">
                    <span className="header-language-label-text">{text}</span>
                </div>
            </div>
        </div>
    );
}

function HeaderLanguage() {
    return (
        <div className="header-language">
            <HeaderLanguageButton
                link="https://nakheel-arabic.visengine.app"
                text="عربي"
            />
            <HeaderLanguageButton
                link="https://nakheel-china.visengine.app"
                text="中文"
            />
            <HeaderLanguageButton
                link="https://nakheel-portuguese.visengine.app"
                text="PT"
            />
            <HeaderLanguageButton
                link="https://nakheel-russian.visengine.app"
                text="RU"
            />
        </div>
    );
}

function HeaderLogo() {
    return (
        <div onClick={() => window.location.reload()} className="header-logo">
            <div className="header-logo-top">
                <img src={NakheelLogo} alt="nakheel-logo" />
            </div>
            {/* <div className="header-logo-bottom">
                <span className="header-logo-text">Dubai, United Arab Emirates</span>
            </div> */}
        </div>
    );
}

// Dropdown Elements

const HeaderDropdownButtonDiv = styled.div`
    border: 1px solid
        ${({ isOpened }) => (isOpened ? "#e6e2e2e5" : "#e6e2e2a8")};
`;

const HeaderDropdownArrowImg = styled.img`
    transform: ${({ isOpened }) => (isOpened ? "rotate(180deg)" : "rotate(0)")};
`;

function HeaderDropdownButton({
    onClick,
    title,
    currentActive,
    type,
    isOpened,
}) {
    const removeExtraChars = () => {
        let extraCharsAmount = title.length + currentActive.length + 2 - 27;
        if (extraCharsAmount > 0) {
            return currentActive.slice(0, -extraCharsAmount - 2) + "..";
        } else {
            return currentActive;
        }
    };

    return (
        <HeaderDropdownButtonDiv
            className="header-dropdown-button"
            onClick={onClick}
            isOpened={isOpened}
        >
            <div className="header-dropdown-button-inner">
                <span className="header-dropdown-button-text">
                    {type === "radio" ? (
                        <>
                            {title}: <b>{removeExtraChars()}</b>
                        </>
                    ) : (
                        title
                    )}
                </span>
                <HeaderDropdownArrowImg
                    isOpened={isOpened}
                    src={DropdownArrow}
                    alt="dropdown-arrow"
                />
            </div>
        </HeaderDropdownButtonDiv>
    );
}

function HeaderDropdownListTitle({ title }) {
    return (
        <div className="header-dropdown-list-title">
            <span className="header-dropdown-title-text">{title}</span>
        </div>
    );
}

// Dropdown Radio-List

const RadioInner = styled.div`
    opacity: ${({ activeStatus }) => (activeStatus ? "1" : "0")};
`;

function HeaderRadioButton({ activeStatus }) {
    return (
        <div className="header-dropdown-list-radio-button">
            <RadioInner
                activeStatus={activeStatus}
                className="header-dropdown-list-radio-button-inner"
            />
        </div>
    );
}

const HeaderElement = styled.div`
    // background-color: ${({ activeStatus }) =>
        activeStatus ? "rgb(245, 245, 245)" : "#FFFFFF"};
    background-color: ${({ activeStatus }) =>
        activeStatus ? "rgba(226, 226, 226, 0.25)" : "rgba(0, 0, 0, 0)"};
`;

function HeaderDropdownListRadio({ elements, stateObject }) {
    return (
        <>
            {elements.map((element) => {
                let activeStatus = element.id === stateObject.state.id;
                return (
                    <HeaderElement
                        onClick={() => stateObject.setState(element)}
                        className="header-dropdown-list-element"
                        activeStatus={activeStatus}
                    >
                        <div className="header-dropdown-list-element-inner">
                            <HeaderRadioButton activeStatus={activeStatus} />
                            <span className="header-dropdown-list-element-text">
                                {element.text}
                            </span>
                        </div>
                    </HeaderElement>
                );
            })}
        </>
    );
}

// Dropdown Checkbox-List

const ChechboxInner = styled.div`
    opacity: ${({ activeStatus }) => (activeStatus ? "1" : "0")};
`;

function HeaderCheckboxButton({ activeStatus }) {
    return (
        <div className="header-dropdown-list-checkbox-button">
            <ChechboxInner
                activeStatus={activeStatus}
                className="header-dropdown-list-checkbox-button-inner"
            />
        </div>
    );
}

function HeaderDropdownListCheckbox({ elements, stateObject }) {
    const editState = (elementId) => {
        let stateTemp = JSON.parse(JSON.stringify(stateObject.state));

        console.log(stateTemp);
        if (elementId.startsWith("_all")) {
            if (!stateTemp.includes(elementId)) {
                for (let element of elements) {
                    if (
                        !stateTemp.includes(element.id) &&
                        element.id !== elementId
                    ) {
                        stateTemp.push(element.id);
                    }
                }
            } else {
                for (let element of elements) {
                    if (
                        stateTemp.includes(element.id) &&
                        element.id !== elementId
                    ) {
                        stateTemp.splice(stateTemp.indexOf(element.id), 1);
                    }
                }
            }
        }

        if (stateObject.state.includes(elementId)) {
            stateTemp.splice(stateTemp.indexOf(elementId), 1);

            for (let element of elements) {
                if (
                    element.id.startsWith("_all") &&
                    stateTemp.includes(element.id)
                ) {
                    stateTemp.splice(stateTemp.indexOf(element.id), 1);
                }
            }
        } else {
            stateTemp.push(elementId);
        }

        if (
            elements.every(
                (element) =>
                    stateTemp.includes(element.id) ||
                    element.id.startsWith("_all") === true
            )
        ) {
            for (let element of elements) {
                if (
                    element.id.startsWith("_all") &&
                    !stateTemp.includes(element.id)
                ) {
                    stateTemp.push(element.id);
                }
            }
        }

        if (stateTemp.length === 0) {
            return JSON.parse(JSON.stringify(stateObject.state));
        }
        return stateTemp;
    };

    return (
        <>
            {elements.map((element) => {
                let activeStatus = stateObject.state.includes(element.id);
                return (
                    <HeaderElement
                        onClick={() =>
                            stateObject.setState(editState(element.id))
                        }
                        className="header-dropdown-list-element"
                        activeStatus={activeStatus}
                    >
                        <div className="header-dropdown-list-element-inner">
                            <HeaderCheckboxButton activeStatus={activeStatus} />
                            <span className="header-dropdown-list-element-text">
                                {element.text}
                            </span>
                        </div>
                    </HeaderElement>
                );
            })}
        </>
    );
}

// Dropdown Element

const HeaderDropdownDiv = styled.div`
    @media screen and (max-width: 1300px) {
        margin-bottom: ${({ isOpened }) => (isOpened ? "10px" : "10px")};
        height: ${({ isOpened, elementsLen }) =>
            isOpened ? elementsLen * 43 + 31 + 15 + 43 + "px" : "43px"};
    }
`;

const HeaderDropdownListDiv = styled.div`
    margin-top: ${({ isOpened }) => (isOpened ? "10px" : "-43px")};
    height: ${({ isOpened, elementsLen }) =>
        isOpened ? elementsLen * 43 + 31 + "px" : "1px"};
    opacity: ${({ isOpened }) => (isOpened ? "1" : "0")};

    .header-dropdown-list-element {
        height: ${({ isOpened }) => (isOpened ? "43px" : "1px")};
    }
`;

function HeaderDropdown({
    type,
    title,
    id,
    elements,
    stateObject,
    currentActiveDropdown,
    setCurrentActiveDropdown,
    setActiveCard,
}) {
    const [isOpened, setIsOpened] = useState(false);
    let elementsLen = elements.length;

    useEffect(() => {
        if (currentActiveDropdown === id) {
            setIsOpened(true);
        } else {
            setIsOpened(false);
        }
    }, [currentActiveDropdown]);

    return (
        <HeaderDropdownDiv
            className="header-dropdown"
            isOpened={isOpened}
            elementsLen={elementsLen}
        >
            <HeaderDropdownButton
                onClick={() => {
                    if (currentActiveDropdown !== id) {
                        setCurrentActiveDropdown(id);
                    } else {
                        setCurrentActiveDropdown("");
                    }
                    setActiveCard(null);
                }}
                title={title}
                currentActive={stateObject.state.text}
                type={type}
                isOpened={isOpened}
            />
            <HeaderDropdownListDiv
                elementsLen={elementsLen}
                isOpened={isOpened}
                className="header-dropdown-list"
            >
                <HeaderDropdownListTitle title={title} />

                <div className="header-dropdown-list-elements">
                    {type === "radio" ? (
                        <HeaderDropdownListRadio
                            elements={elements}
                            stateObject={stateObject}
                        />
                    ) : (
                        <HeaderDropdownListCheckbox
                            elements={elements}
                            stateObject={stateObject}
                        />
                    )}
                </div>
            </HeaderDropdownListDiv>
        </HeaderDropdownDiv>
    );
}

// Switch Element
function HeaderSwitch({
    switchChecked,
    setSwitchChecked,
    setActiveCard,
    labelText,
}) {
    const switchChange = () => {
        setSwitchChecked(!switchChecked);
        setActiveCard(null);
    };

    return (
        <div className="header-switch">
            <div className="header-switch-inner" onClick={switchChange}>
                <Switch
                    className="header-switch-element"
                    onChange={() => null}
                    checked={switchChecked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor="#FA854F"
                    offColor="#b3b3b3"
                    handleDiameter={15}
                    height={17}
                    width={31}
                />
                <div className="header-switch-label">
                    <span className="header-switch-text">{labelText}</span>
                </div>
            </div>
        </div>
    );
}

// Button Element

const HeaderButtonDiv = styled.div`
    background-color: ${({ isActive }) =>
        isActive ? "#F88350" : "rgba(197, 197, 197, 0.153)"};
    // font-weight: ${({ isActive }) => (isActive ? "500" : "400")};
    // font-size: ${({ isActive }) => (isActive ? "12.25px" : "12px")};

    &:hover {
        background-color: ${({ isActive }) =>
            isActive ? "#DF7949" : "rgba(226, 226, 226, 0.153)"};
    }
`;

const HeaderButtonImage = styled.img`
    transform: ${({ isActive }) => (isActive ? "rotate(-0.5turn)" : "0")};
`;

function HeaderButton({ text, buttonChecked, setButtonCheched }) {
    return (
        <HeaderButtonDiv
            className="header-button"
            isActive={buttonChecked}
            onClick={() => setButtonCheched(!buttonChecked)}
        >
            <div className="header-button-inner">
                <span className="header-button-text">{text}</span>
                <HeaderButtonImage
                    src={MasterCommunities}
                    alt="master-communities-logo"
                    isActive={buttonChecked}
                />
            </div>
        </HeaderButtonDiv>
    );
}

export default function Header({
    data,

    currentActiveDropdown,
    setCurrentActiveDropdown,

    directionSwitchChecked,
    setDirectionSwitchChecked,
    radiusSwitchChecked,
    setRadiusSwitchChecked,
    newProjectsSwitchChecked,
    setNewProjectsSwitchChecked,
    masterCommunitiesChecked,
    setMasterCommunitiesChecked,

    setActiveCard,
}) {
    return (
        <div className="header">
            <div className="header-container">
                <div className="header-left">
                    <HeaderLogo />
                </div>
                <div className="header-right">
                    {data.map((dropdown) => (
                        <HeaderDropdown
                            type={dropdown.type}
                            title={dropdown.title}
                            id={dropdown.id}
                            elements={dropdown.elements}
                            stateObject={dropdown.stateObject}
                            currentActiveDropdown={currentActiveDropdown}
                            setCurrentActiveDropdown={setCurrentActiveDropdown}
                            setActiveCard={setActiveCard}
                        />
                    ))}
                    <HeaderButton
                        text="Master Communities"
                        buttonChecked={masterCommunitiesChecked}
                        setButtonCheched={setMasterCommunitiesChecked}
                    />
                    <div className="header-right-switchbox">
                        <HeaderSwitch
                            switchChecked={directionSwitchChecked}
                            setSwitchChecked={setDirectionSwitchChecked}
                            setActiveCard={setActiveCard}
                            labelText="Show Distances"
                        />
                        <HeaderSwitch
                            switchChecked={radiusSwitchChecked}
                            setSwitchChecked={setRadiusSwitchChecked}
                            setActiveCard={setActiveCard}
                            labelText="Show Circles"
                        />
                        <HeaderSwitch
                            switchChecked={newProjectsSwitchChecked}
                            setSwitchChecked={setNewProjectsSwitchChecked}
                            setActiveCard={setActiveCard}
                            labelText="New Projects"
                        />
                    </div>
                    <HeaderLanguage />
                </div>
            </div>
        </div>
    );
}
