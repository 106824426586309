export const dropdownsData = (
    checkboxFiltersRadio,
    setCheckboxFiltersRadio
) => {
    return ([
    {
        type: 'checkbox',
        title: 'Developments',
        id: 'developments',
        elements: [
            {
                text: 'Nakheel',
                id: 'nakheel'
            },
            {
                text: 'Meydan',
                id: 'meydan'
            }
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    },
    {
        type: 'checkbox',
        title: 'Project Type',
        id: 'project_type',
        elements: [
            {
                text: 'Villas',
                id: 'villas'
            },
            {
                text: 'Apartments',
                id: 'apartments'
            },
            {
                text: 'Plots',
                id: 'plots'
            },
            {
                text: 'Apartments & Villas',
                id: 'apartments_villas'
            },
            {
                text: 'Plots & Villas & Apartments',
                id: 'plots_villas_apartments'
            },
            {
                text: 'Show All',
                id: '_all_project_type'
            },
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    },
    {
        type: 'checkbox',
        title: 'Infrastructure',
        id: 'infrastructure',
        elements: [
            {
                text: 'Landmarks',
                id: 'landmarks'
            },
            {
                text: 'Transport (Metro)',
                id: 'transport'
            },
            {
                text: 'Education',
                id: 'education'
            },
            {
                text: 'Health',
                id: 'health'
            },
            {
                text: 'Retail or Shopping',
                id: 'retail_or_shopping'
            }
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    }
    ])
}