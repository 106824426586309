import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { styled } from "styled-components";

import './WindowCard.scss'

import ExitCross from '../../../static/images/card-exit-cross-black.svg'
import ArrowLeft from '../../../static/images/card-arrow-carousel-left.svg'
import ArrowRight from '../../../static/images/card-arrow-carousel-right.svg'



export function WindowCardTabs({ data, currentActiveTabFiles, setCurrentActiveTabFiles }) {
    return (
        <div className="window-card-tabs">
            <div className="window-card-tabs-inner">
                {
                    data.map((tabData) => (
                        <div 
                            className={
                                currentActiveTabFiles === tabData.file ? (
                                    "window-card-tab window-card-tab-active"
                                ) : (
                                    "window-card-tab"
                                )
                            } 
                            onClick={() => setCurrentActiveTabFiles(tabData.file)}
                        >
                            <span className="window-card-tab-text">{tabData.title}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}


const WindowCardImagesDiv = styled.div`
    height: calc(100%${({status}) => status && ' - 50px'});
    margin-top: ${({status}) => status ? '50px' : '0'};
`

export function WindowCardImagesFile({ data }) {

    const [currentActiveTabFiles, setCurrentActiveTabFiles] = useState(data[0].file)

    return (
        <>
            {
                data.length > 1 && (
                    <WindowCardTabs 
                        data={data} 
                        currentActiveTabFiles={currentActiveTabFiles} 
                        setCurrentActiveTabFiles={setCurrentActiveTabFiles} 
                    />
                )
            }
            <WindowCardImagesDiv
                className="window-card-images"
                status={data.length > 1}
            >
                {
                    currentActiveTabFiles?.map((file) => (
                        <div className="window-card-image">
                            <img src={file} alt="window-card-image" />
                        </div>
                    ))
                }
            </WindowCardImagesDiv>
        </>
    )
}


const CircleDiv = styled.div`
    background-color: ${({status}) => status ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"};
`

function WindowCardGalleryCircles({ previews, setImageIndex, activeCircle, setActiveCircle }) {
    return (
        <div className="window-card-gallery-circles">
            <div className="window-card-gallery-circles-inner">
                {
                    previews.map((preview) => (
                        <CircleDiv status={activeCircle === preview} className="window-card-gallery-circle" onClick={() => {
                            setActiveCircle(preview)
                            setImageIndex(previews.indexOf(preview))
                        }}></CircleDiv>
                    ))
                }
            </div>
        </div>
    )
}


const WindowCardGalleryDiv = styled.div`
    background-image: url(${({image}) => image});  
`

export function WindowCardGallery({ 
    cardActiveImageIndex, 
    images, 
    setActiveWindow, 
    setActiveCard,
    setCardImageIndex
}) {

    // ---------------------------------
    // *** Code needs to be replaced ***
    // ---------------------------------
    
    const [activeImage, setActiveImage] = useState()
    const [imageIndex, setImageIndex] = useState(cardActiveImageIndex)
    const [activeCircle, setActiveCircle] = useState()
    const [imagesList, setImagesList] = useState(images)

    useEffect(() => {
        let image = imagesList[imageIndex]
        setActiveImage(image)
        setActiveCircle(image)
        setCardImageIndex(imageIndex)
    }, [imageIndex])


    // Track updates from card
    useEffect(() => {
        setImagesList(images)
        setActiveImage(images[imageIndex])
    }, [images])

    useEffect(() => {
        setImageIndex(cardActiveImageIndex)
    }, [cardActiveImageIndex])


    const changeImage = (action) => {
        let nextIndex = imageIndex+action
        if (nextIndex > imagesList.length-1) {
            setImageIndex(0)
        } else if (nextIndex < 0) {
            setImageIndex(imagesList.length-1)
        } else {
            setImageIndex(nextIndex)
        }
    }
    
    // Swipe handlers
    const handlers = useSwipeable({
        onSwipedLeft: () => changeImage(1),
        onSwipedRight: () => changeImage(-1),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    })

    return (
        <WindowCardGalleryDiv 
            className="window-card-gallery"
            image={activeImage}
            {...handlers}
        >
            <div 
                className="window-card-gallery-arrow window-card-gallery-arrow-left" 
                onClick={() => changeImage(-1)}
            >
                <img src={ArrowLeft} alt="arrow-left" />
            </div>
            <WindowCardGalleryCircles 
                previews={images}
                setImageIndex={setImageIndex}
                activeCircle={activeCircle}
                setActiveCircle={setActiveCircle}
            />
            <div 
                className="window-card-gallery-arrow window-card-gallery-arrow-right"
                onClick={() => changeImage(1)}
            >
                <img src={ArrowRight} alt="arrow-right" />
            </div>
            {/* <div className="window-card-gallery-back" /> */}
        </WindowCardGalleryDiv>
    )
}


const WindowCardDiv = styled.div`
    opacity: ${({status}) => status ? '1' : '0'};
    pointer-events: ${({status}) => status ? 'all' : 'none'} !important;
`

export default function WindowCard({ windowContent, setWindowContent, activeWindow, setActiveWindow, setActiveCard }) {
    return (
        <div className="window-card">
            <WindowCardDiv status={activeWindow && windowContent} className="window-card-container">
                <div 
                    className="window-card-cross" 
                    onClick={() => {
                        setActiveWindow(false)
                        setActiveCard(true)

                        setWindowContent(<h1>Empty.</h1>)
                    }}
                >
                    <img src={ExitCross} alt="exit-cross" />
                </div>
                <div className="window-card-inner">
                    { windowContent && (
                        windowContent
                    ) }
                </div>
            </WindowCardDiv>
        </div>
    )
}