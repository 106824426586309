import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.scss';

import Main from './pages/main/Main'


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route path='/' element={
            <>
              <h1 style={{textAlign: "center"}}>Welcome to <a href='https://visengine.com'>VisEngine</a></h1>
            </>
          } /> */}

          <Route path='/' element={<Main />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
